import Stripe from 'stripe';

import { CheckoutPrice, CheckoutProduct, ProductPricingInterval } from '../products/productTypes';
import { MNPaymentMethod } from '../customers/customerTypes';
import {
    Environment,
    JWSRenewalInfoDecodedPayload,
    JWSTransactionDecodedPayload,
    Status,
} from '@apple/app-store-server-library';

export enum MNSubscriptionProvider {
    APPLE = 'apple',
    STRIPE = 'stripe',
}

export type MNSubscriptionCoupon = {
    id: string;
    name?: string;
    percentOff?: number;
    amountOff?: number;
    duration: 'forever' | 'once' | 'repeating';
    durationInMonths?: number;
    currency?: string;
};

export type MNSubscriptionCost = {
    amount: number;
    currency: string | undefined;
    interval: ProductPricingInterval;
};

export type MNSubscriptionPlan = {
    _id: string;
    name: string;
    adminId: string;

    quantity: number;
    maxSeats: number;

    status: Stripe.Subscription.Status | Status;
    active: boolean;

    createdAt?: number;
    renewAt?: number;

    cancelStatus?: {
        cancelAtPeriodEnd: boolean;
        cancelAt: number; // timestamp of when the subscription will be canceled
        canceledAt: number; // timestamp of when the subscription cancellation was requested
    };

    coupon?: MNSubscriptionCoupon;

    paymentMethod?: MNPaymentMethod;

    product?: CheckoutProduct;
    price?: CheckoutPrice;

    cost: MNSubscriptionCost;
};

export type MNSubscriptionTeamDomainSettings = {
    name: string;
    autoAddUsers: boolean;
    autoAddDuration: number;
    autoAddMaxRenewals: number;
    domains: string[];
};

type MNSubscriptionPartial = {
    _id: string;
    adminId: string;

    canceled?: boolean;
    cancellationTimestamp?: number;
    requestedCancellationTimestamp?: number;
    provider?: MNSubscriptionProvider;

    team?: MNSubscriptionTeamDomainSettings;
};

export type MNStripeSubscription = MNSubscriptionPartial & {
    // Older subscriptions may not have this field
    provider?: MNSubscriptionProvider.STRIPE;
    detail: Stripe.Subscription;
};

export type AppStoreSubscriptionDetail = {
    status: Status;
    environment: Environment;
    transactionInfo: JWSTransactionDecodedPayload;
    renewalInfo: JWSRenewalInfoDecodedPayload;
};

export type MNAppStoreSubscription = MNSubscriptionPartial & {
    provider: MNSubscriptionProvider.APPLE;
    detail: AppStoreSubscriptionDetail;
};

/**
 * "Medium serve" subscription data. Includes the subscription details, but not the plan details / team members. etc.
 * This is what is actually stored in the database — the other types enriched / derived from this.
 */
export type MNSubscription = MNAppStoreSubscription | MNStripeSubscription;

/**
 * "Small serve" subscription data. Just the bare minimum to show the user's current subscription status.
 */
export type MNSubscriptionStatus = {
    active: boolean;
    status: Stripe.Subscription.Status | Status;
    planName: string;
};

/**
 * "Full serve" subscription data. Includes all the details of a user's subscription.
 */
export type MNFullSubscriptionDetail = MNSubscription &
    MNSubscriptionStatus & {
        teamMemberIds: string[];
        teamMemberCount: number;
        plan: MNSubscriptionPlan | undefined;
    };
