export const BOARD_ID_TRASH_APPEND = '-trash';

export enum BoardSections {
    CANVAS = 'CANVAS',
    INBOX = 'INBOX',
    TRASH = 'TRASH',
    DELETED = 'DELETED',
    ATTACHED = 'ATTACHED',
}

export enum InboxState {
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
}

export enum RootBoardTypes {
    WORKSPACE = 'WORKSPACE',
    ROOT_WORKSPACE = 'ROOT_WORKSPACE',
    TEMPLATES = 'TEMPLATES',
    QUICK_NOTES = 'QUICK_NOTES',
}

export enum RootBoardTitles {
    WORKSPACE = 'Home',
    TEMPLATES = 'Templates',
    QUICK_NOTES = 'Quick notes',
    ROOT_WORKSPACE = 'Root',
}

export const WORKSPACE_BOARD_TITLES = {
    WORK: 'Work',
    PERSONAL: 'Personal',
    ARCHIVE: 'Archive',
    SHARED: 'Shared with you',
    OLD_HOME_BOARD: 'Your old home board',
};

export enum WorkspaceTypes {
    CUSTOM = 'CUSTOM',
    ARCHIVE = 'ARCHIVE',
    SHARED = 'SHARED',
}

export const BOARD_DISPLAY_NAME = 'Board';
export const BOARD_DEFAULT_TITLE = 'New Board';

export const BOARD_CANVAS_ORDER_LOAD = 'BOARD_CANVAS_ORDER_LOAD';

export const CURRENT_BOARD_HEADER_TITLE_EDITOR_KEY = 'CURRENT_BOARD_TITLE';
